export const proposalForm = {
  data() {
    return {
      tabIndex: 0,

      data: {
        general: {},
        environment: {},
        states: [{}],
      },
      editable: false,
      proposalState: '',
      missingFields: [],
      loading: false,
    };
  },
  computed: {
    isMissingListEmpty() {
      return this.isEmpty(this.missingList);
    },
  },
  watch: { $route: 'loadData' },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.axios.get(`/documents/${this.$route.params.id}`)
        .then((response) => {
          this.data = response.data.document;
          this.editable = response.data.editable;
          this.proposalState = this.data.states.name;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    checkMissingFields() {
      const data = {};
      data.title = this.$v.data.title;
      const environment = this.getMissingFieldsEnvironment();
      const samples = this.getMissingFieldsSamples();
      const unfilteredObject = {
        ...data,
        ...this.$v.data.general,
        ...environment,
        ...samples,
      };
      const filteredFields = Object.keys(unfilteredObject)
        .filter((key) => !key.startsWith('$'))
        .reduce((obj, key) => ({
          ...obj,
          [key]: unfilteredObject[key],
        }), {});

      this.missingFields = Object.keys(filteredFields).filter((key) => filteredFields[key].$invalid === true);
    },
    getMissingFieldsEnvironment() {
      if (this.data.general.end_station || this.infrastructure === 'cryoem') {
        return this.$v.data.environment;
      }
      return {};
    },
    getMissingFieldsSamples() {
      return this.infrastructure === 'synchrotron' ? this.$v.data.samples : { samples: this.$v.data.samples };
    },
    updateSubmitValidator($event) {
      this.$v.data.general.terms_and_conditions = $event;
      this.checkMissingFields();
    },
  },
};
